.App {
  background-color: #191724;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-opacity: 0.5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.analysis-box {
    margin-top: 20px;
    padding: 20px;
    background-color: #f8f8f8; /* Light background */
    color: #333; /* Dark gray text */
    border: 1px solid #ddd; /* Subtle border */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    font-family: 'Arial', sans-serif; /* Sans-serif font */
    font-size: 16px; /* Adequate font size */
    line-height: 1.6; /* Spacing between lines */
    width: 80%; /* Limiting width for better readability */
    word-wrap: break-word; /* Ensuring long words don't overflow */
    text-align: left; /* Align text to left */
    margin-bottom: 20px;
}

.analysis-header {
    font-family: 'Arial', sans-serif;
    font-size: 20px; /* Slightly larger font for the header */
    color: #333; /* Dark gray to match the text */
    margin-bottom: 15px; /* Space between header and text */
}

.charts-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    width: 83%;
    /* Adjusts the number of columns based on the container's width */
}

.chart-container {
    padding: 20px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.loader {
    border: 6px solid #f3f3f3; /* Light grey border */
    border-top: 6px solid #3498db; /* Blue border */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes border-pulse {
    50% {
        border-color: rgba(255, 255, 255, 0);
    }
}
